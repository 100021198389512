import React from 'react'
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import Section from "../components/Section"

const PoliticallyExposedPersonPage = () => (
  <Layout footerHalf={false}>
    <SEO
      title="Personne Politiquement Exposée (PPE)"
      description="Une Personne Politiquement Exposée (PPE) est une personne qui exerce ou a exercé depuis moins d’un an une haute fonction politique, juridictionnelle ou administrative ou encore, une haute fonction pour le compte d’une institution internationale publique."
    />
    <Section className="py-5" css={{ marginTop: "60px" }}>
      <Container className="content">
        <h1 className="mb-0">Personne Politiquement Exposée (PPE)</h1>
      </Container>
    </Section>
    <Section className="pt-0 content">
      <Container>
        <h2>Définition :</h2>
        <ul>
          <li className={'pb-3'}>Une Personne Politiquement Exposée (PPE) est une personne qui exerce ou a exercé depuis moins d’un an une haute fonction politique, juridictionnelle ou administrative ou encore, une haute fonction pour le compte d’une institution internationale publique : chef d’Etat, chef de gouvernement, membre d’un gouvernement national ou de la Commission Européenne ; membre d’une assemblée parlementaire nationale ou du Parlement Européen, membre de l’organe dirigeant d’un parti ou groupement politique soumis aux dispositions de la loi n° 88-227 du 11 mars 1988 ou d’un parti ou groupement politique étranger ; membre d’une cour suprême, d’une cour constitutionnelle ou d’une autre haute juridiction dont les décisions ne sont pas, sauf circonstances exceptionnelles, susceptibles de recours ; membre d’une cour des comptes ; dirigeant ou membre de l’organe de direction d’une banque centrale ; ambassadeur, chargé d’affaires ; officier général ou officier supérieur assurant le commandement d’une armée ; membre d’un organe d’administration, de direction ou de surveillance d’une entreprise publique ; Directeur, directeur adjoint, membres du conseil d’une organisation internationale créée par un traité, ou une personne qui occupe une position équivalente en son sein.</li>
          <li className={'pb-3'}>Sont considérées comme des personnes connues pour être membre direct de la famille d’une PPE : le conjoint ou le concubin notoire ; le partenaire lié par un pacte civil de solidarité ou par un contrat de partenariat enregistré en vertu d’une loi étrangère ; en ligne directe, les ascendants et descendants, au premier degré, ainsi que leur conjoint, leur partenaire lié par un pacte civil de solidarité ou par un contrat de partenariat enregistré en vertu d’une loi étrangère.</li>
          <li className={'pb-3'}>Sont considérés comme des personnes connues pour être étroitement associées à une PPE : toute personne physique identifiée comme étant le bénéficiaire effectif d’une personne morale conjointement avec cette PPE, toute personne physique identifiée comme étant seul bénéficiaire effectif d’une personne morale connu pour avoir été établi au profit de cette PPE, toute personne physique connue comme entretenant des liens d’affaires étroits avec cette PPE.</li>
        </ul>
      </Container>
    </Section>
  </Layout>
)

export default PoliticallyExposedPersonPage
